.App {
  margin:      auto;
  max-width:   672px;
  font-family: 'Karla', sans-serif;

  @media print {
    max-width: 520px;
    margin: 10px;
  }
}

a {
  outline: none !important;
  text-decoration: none;
}

a:link, a:visited, a:hover, a:active {
  color: #000;
  outline: none;
}