@charset "UTF-8";
/*
	Georgia
	Demo css to show basic, blog-like styling
	Visit http://www.ulysses.app/styles for full reference

*/
.WorkHeader {
  padding: 15px 0 5px;
  position: fixed;
  width: 100%;
  max-width: 672px;
  box-sizing: border-box;
  background: #fff;
  font-size: 115%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 736px) {
    .WorkHeader {
      padding: 15px 10px 5px; } }
  .WorkHeader .wordCount {
    font-size: 60%; }
  .WorkHeader .WorkBack svg {
    font-size: 80%; }
  .WorkHeader .WorkBack:hover {
    color: red; }
    .WorkHeader .WorkBack:hover svg {
      fill: red; }
  .WorkHeader .WorkButtons a {
    margin-left: 20px; }
    .WorkHeader .WorkButtons a:hover svg {
      fill: red; }
  @media print {
    .WorkHeader {
      display: none; } }

.WorkFooter .FooterComment {
  margin-top: 75px;
  border: 1px solid #666;
  padding: 5px 10px;
  display: inline-block;
  font-family: 'Karla', sans-serif;
  border-radius: 4px; }

@media print {
  .WorkFooter {
    display: none; } }

.Work {
  text-align: left;
  font-family: georgia, serif;
  font-size: 120%;
  line-height: 1.6;
  padding: 50px 2em;
  background: whitesmoke;
  color: #141414;
  /* Code Highlighting */
  /* Markup Highlighting */ }
  .Work a {
    text-decoration: underline; }
  .Work h1 {
    font-family: 'Karla', sans-serif;
    font-size: 170%;
    line-height: 1.2;
    font-style: normal;
    font-weight: normal;
    margin: 0.5em 0 2em; }
  .Work h2 {
    font-weight: bold;
    font-size: 150%;
    line-height: 140%;
    margin: 1.5em 0 0.75em; }
  .Work h3 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: 130%;
    letter-spacing: 0.15em;
    margin: 1.75em 0 0.75em; }
  .Work h4 {
    font-weight: normal;
    font-size: 130%;
    letter-spacing: 0.1em;
    margin: 1.5em 0 0.75em; }
  .Work h5 {
    font-weight: normal;
    font-style: italic;
    font-size: 130%;
    margin: 1.5em 0 0.75em; }
  .Work h6 {
    font-weight: bold;
    font-size: 100%;
    letter-spacing: 0.1em;
    margin: 1.5em 0 0.75em; }
  .Work p {
    text-align: justify;
    font-size: 100%;
    margin: 0.5em 0 0; }
  .Work p + p {
    text-indent: 1.5em; }
  .Work h1 + p {
    text-indent: 0; }
  .Work p + ul, .Work p + ol, .Work p + dl {
    margin-bottom: 1.5em; }
  .Work img {
    max-width: 100%; }
  .Work figure {
    margin: 2em 0 1.5em 0;
    width: 100%;
    text-align: center; }
  .Work figure img {
    border: 1px solid #ccc; }
  .Work figcaption {
    font-size: 66%;
    padding: 1em;
    padding-top: 0.5em;
    color: #aaa; }
  .Work blockquote {
    border-left: 5px solid #eee;
    padding-left: 1.5em;
    margin: 1em 0; }
  .Work blockquote p {
    text-indent: 0 !important;
    font-style: italic; }
  .Work blockquote small {
    display: block;
    line-height: 1.7em; }
  .Work ul, .Work ol, .Work dl {
    text-align: left; }
  .Work ul, .Work ol {
    padding-left: 1.2em; }
  .Work ul li, .Work ol li {
    padding-left: 0.3em;
    margin-top: 0.6em; }
  .Work li p {
    margin-top: 0px;
    text-indent: 0em; }
  .Work li {
    margin-bottom: 0.6em; }
  .Work hr {
    border: none;
    margin: 1.5em 0 1.5em;
    font-size: 200%;
    color: #ddd;
    height: 1em; }
  .Work hr:after {
    content: "❧"; }
  .Work h1 + hr {
    margin: -0.5em 0 1.5em;
    font-size: 400%; }
  .Work ol#footnotes {
    margin-top: 4.5em;
    border-top: 1px solid #eee;
    padding-top: 1em;
    font-size: 80%;
    padding-left: 1.5em; }
  .Work ol#footnotes p {
    text-indent: 0px; }
  .Work figure + p {
    text-indent: 0em !important; }
  .Work code {
    font-family: 'Courier', monospace;
    font-size: 80%;
    color: #f8f8f2; }
  .Work p > code {
    padding: 0.5em 0.5em;
    background: #272822;
    border-radius: 5px; }
  .Work pre {
    color: #f8f8f2;
    text-align: left;
    line-height: 1.2em;
    overflow-x: scroll;
    background: #272822;
    padding: 20pt;
    margin: 20pt 0;
    border-radius: 3pt;
    white-space: pre-wrap;
    tab-size: 4; }
  .Work .syntax-entity {
    color: #fd971f; }
  .Work .syntax-tag {
    color: #f92672; }
  .Work .syntax-keyword {
    color: #f92672; }
  .Work .syntax-parameter {
    color: #a6e22e; }
  .Work .syntax-string {
    color: #e6db74; }
  .Work .syntax-constant {
    color: #9effff; }
  .Work .syntax-variable {
    color: #a6e22e; }
  .Work .syntax-escape {
    color: #ae81ff; }
  .Work .syntax-comment {
    color: #75715e; }
  .Work .syntax-error {
    color: #f8f8f0;
    background: #f92672;
    padding: 2px 6px; }
  .Work .syntax-heading {
    font-weight: bold;
    color: #ae81ff; }
  .Work .syntax-italic {
    font-style: italic; }
  .Work .syntax-bold {
    font-weight: bold; }
  .Work .syntax-deleted {
    color: #b31d28;
    background-color: #ffeef0; }
  .Work .syntax-inserted {
    color: #22863a;
    background-color: #f0fff4; }
  .Work .syntax-changed {
    color: #e36209;
    background-color: #ffebda; }
  .Work .syntax-link {
    text-decoration: underline;
    color: #008fff; }
  .Work .syntax-list {
    color: #fd971f; }
  @media screen and (max-width: 736px) {
    .Work {
      font-size: 110%;
      padding: 50px 1em; }
      .Work h1 {
        font-size: 150%; } }
