.Index {

  padding: 20px 1em 10px;

  h2 {
    margin: 10px 0 25px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 125%;
      margin-bottom: 15px;
      span.wordCount {
        font-size: 70%;
        color: #666;
      }
      a {
        &:hover {
          color: red;
        }
      }
      .download {
        color: #666;
        text-decoration: underline;
        font-size: 70%;
        margin: 0 15px 0 0;
        &:hover {
          color: #000;
        }
      }
    }
  }
}